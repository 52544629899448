import axios from 'axios'
import { SubmitStatus } from '@utils/submit-status'

export const state = {
  authorizationData: {},
  submitStatus: SubmitStatus.NotSubmitted,
  error: {},
}

export const getters = {
  authorizationData: (currentState) => currentState.authorizationData,
  authorizationSubmitStatus: (currentState) => currentState.submitStatus,
  authorizationError: (currentState) => currentState.error,
}

export const mutations = {
  AUTHORIZATION_DATA(currentState, newValue) {
    currentState.authorizationData = newValue
  },
  SET_SUBMIT_STATUS(currentState, newValue) {
    currentState.submitStatus = newValue
  },
  SET_ERROR(currentState, newValue) {
    currentState.error = newValue?.data || null
  },
}

export const actions = {
  createAuthorization(
    { commit },
    {
      sessionId,
      documentedGuidelines,
      attachments,
      episodeNote,
      clinicalData,
      excludeFromAuthRequest,
      isMcgAuthWorkflow,
    }
  ) {
    commit('SET_SUBMIT_STATUS', SubmitStatus.Processing)
    return axios
      .post(
        `/api/Dtr/Documentation?session=${sessionId}&isMcgAuthWorkflow=${isMcgAuthWorkflow}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          documentedGuidelines,
          attachments,
          episodeNote,
          clinicalData,
          excludeFromAuthRequest,
        }
      )
      .then((response) => {
        const authorizationData = response?.data
        commit('AUTHORIZATION_DATA', authorizationData)
        commit('SET_SUBMIT_STATUS', SubmitStatus.Submitted)
        commit('SET_ERROR', null)
        return authorizationData
      })
      .catch((error) => {
        const authorizationData = { ApiError: error }
        commit('AUTHORIZATION_DATA', authorizationData)
        commit('SET_SUBMIT_STATUS', SubmitStatus.Error)
        commit('SET_ERROR', error?.response)
        return authorizationData
      })
  },
  setAuthorizationSubmitStatus({ commit }, submitStatus) {
    commit('SET_SUBMIT_STATUS', submitStatus)
  },
}
